import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import './UserProfile.css';
import ReadingLog from './ReadingLog';

function UserProfile() {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async (user) => {
      if (user) {
        console.log('User state changed:', user.uid);
        const userDocRef = doc(db, 'users', user.uid);
        try {
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            console.log('Document data:', docSnap.data());
            setUserData(docSnap.data());
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Error fetching document:', error);
        }
      } else {
        console.log('No user is logged in');
        navigate('/login');
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      fetchUserData(user);
    });

    return () => unsubscribe();
  }, [navigate]);

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="user-profile-container">
      <h2>{userData.username}'s Profile</h2>
      <p><strong>Total Word Count:</strong> {userData.totalWordCount}</p>
      <ReadingLog />
      <p><strong>Completed Contents:</strong></p>
      <ul>
        {userData.completedContents.map((contentId) => (
          <li key={contentId}>{contentId}</li>
        ))}
      </ul>
    </div>
  );
}

export default UserProfile;
