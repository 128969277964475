// src/components/SearchBar.js
import React from 'react';
import './SearchBar.css';
import { genres, readingLevels, wordCounts } from '../data/filters';

function SearchBar({ searchTerm, setSearchTerm, genre, setGenre, readingLevel, setReadingLevel, wordCount, setWordCount }) {
  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder="Search by title or summary"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      <select value={genre} onChange={(e) => setGenre(e.target.value)} className="search-select">
        <option value="">All Genres</option>
        {genres.map((genre, index) => (
          <option key={index} value={genre}>{genre}</option>
        ))}
      </select>
      <select value={readingLevel} onChange={(e) => setReadingLevel(e.target.value)} className="search-select">
        <option value="">All Levels</option>
        {readingLevels.map((level, index) => (
          <option key={index} value={level}>{level}</option>
        ))}
      </select>
      <select value={wordCount} onChange={(e) => setWordCount(e.target.value)} className="search-select">
        <option value="">All Lengths</option>
        {wordCounts.map((wordCount, index) => (
          <option key={index} value={wordCount}>{wordCount}</option>
        ))}
      </select>
    </div>
  );
}

export default SearchBar;
